import React from 'react';
import { useEffect, useState } from 'react';
import * as Icons from '../icons';

import styles from './icon-button.module.css';



export default function ToggleButton({isActive, icon, iconActive, onClick}) {

  const callback = () => {
    onClick(!isActive);
  };

  return (
    <button 
      onClick={callback} 
      className={`${styles.iconButton} ${isActive ? "activeIcon" : ""}`} 
    >
    {isActive? iconActive: icon} 
    </button>
  );
}

