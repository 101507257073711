import Topnav from '../component/topnav/topnav';
import TitleL from '../component/text/title-l';
import TitleM from '../component/text/title-m'
import PlaylistCardS from '../component/cards/playlist-card-s';
import PlaylistCardM from '../component/cards/playlist-card-m';

import styles from "./home.module.css";
import { useEffect, useState } from 'react';
import { PLAYLIST } from '../data/index';
import { cacheGet, cacheSet } from '../functions/cacheManager';
import CONST from '../constants';

function Home(){
    const[sections, setSections] = useState([]);

    useEffect(() => {
        const url = CONST.API_URL_BASE+'spa_playlist';    

        let cache_data = cacheGet(url);
        if (cache_data != null) {
            setSections(cache_data)
            return
        }
        
		fetch(url)
		   .then((response) => response.json())
		   .then((data) => {
			  setSections(data)
              cacheSet(url, 10000, data)
		   })
		   .catch((err) => {
			  console.log(err.message)
		   });
	 }, []);

    return (
        <div className={styles.Home}>
            <div className={styles.HoverBg}></div>
            <div className={styles.Bg}></div>

            <Topnav />

            <div className={styles.Content}>
            {sections.length == 0 ? '':
                <section>
                    <div className={styles.SectionTitle}>
                        <TitleL>{sections[0].section_name}</TitleL>
                    </div>

                    <div className={styles.SectionCards}>
                        {sections[0].playlists.map((item) => {
                            return (
                                <PlaylistCardS 
                                    key={item.title}
                                    data={item}
                                />
                            );
                        })}
                    </div>
                </section>
            }

                {/* <section style={{display: 'none'}}>
                    <div className={styles.SectionTitle}>
                        <TitleM>Yakında çalınanlar</TitleM>
                    </div>
                    
                    <div className={styles.SectionCardsMedium}>
                        {PLAYLIST.slice(0, 6).map((item) => {
                            return (
                                <PlaylistCardM 
                                    key={item.title}
                                    data={item}
                                />
                            );
                        })}
                    </div>
                </section> */}

                {sections.slice(1).map((item) => {
                    return (
                        <section key={item.section_name}>
                            <div className={styles.SectionTitle}>
                                <TitleM>{item.section_name}</TitleM>
                            </div>

                            <div className={styles.SectionCardsMedium}>
                                {item.playlists.map((item) => {
                                    return (
                                        <PlaylistCardM 
                                            key={item.link}
                                            data={item}
                                        />
                                    );
                                })}
                            </div>
                        </section>
                    );
                })}

            </div>
        </div>
    );
}

export default Home;