import Topnav from '../component/topnav/topnav';
import TitleM from '../component/text/title-m'
import SearchPageCard from '../component/cards/searchpage-card';
import { SEARCHCARDS } from '../data/index';

import styles from "./test.module.css";

import { useState, useEffect } from 'react';


import PlaylistTrack from '../component/playlist/playlist-track';
import { PLAYLIST } from "../data/index";


import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { changeTrack } from '../actions';

function Test(props){

    const [albums, setAlbums] = useState();
    const[playlistIndex, setPlaylistIndex] = useState(undefined);
    const[isthisplay, setIsthisPlay] = useState(false);

    useEffect(() => {
		setIsthisPlay(playlistIndex === props.trackData.trackKey[0])
	})

    useEffect(() => {
        fetch('https://cors-anywhere.herokuapp.com/https://mdundo.com/app/playlists_get')
        .catch((error) => console.log(error))
        .then((response) => response.json())
        .then((data) => {
            var temp = [];
            data.playlists.forEach(element => {
                temp.push({'title':element.title, 'bgcolor':'#ccc', 'imgurl':element.thumb_url});
            });
            temp = temp.slice(0,20);
            console.log(temp);
            setAlbums(temp);
        });
    }, []);

    return (
        <div className={styles.SearchPage}>
            <Topnav search={true}/>

            <div className={styles.Search}>
                <TitleM>Search</TitleM>
                <div className={styles.SearchCardGrid}>
                    {albums ? albums.map((card) => {
                        return (
                            <SearchPageCard 
                                key={card.title}
                                cardData={{
                                    bgcolor: card.bgcolor,
                                    title: card.title,
                                    imgurl: card.imgurl,
                                }}
                            />
                        );
                    }) : null}
                </div>
            </div>

            

			{PLAYLIST.map((item) => {
                if(item.link == 'dailymix1'){
                    return (
                        <div className={styles.Search}>
                            <TitleM>Playlist</TitleM>

                            <div className={styles.PlaylistSongs}>
                            {item.playlistData.map((song) => {
                                return (
                                    <button 
                                    key={song.index} 
                                    onClick={() => props.changeTrack([PLAYLIST.indexOf(item), item.playlistData.indexOf(song)])} 
                                    className={styles.SongBtn}
                                >
                                    <PlaylistTrack 
                                        data={{
                                            listType: item.type,
                                            song: song
                                        }}
                                    />
                                </button>
                                )
                            })} 
                            </div>
                        </div>
                    )
                }
            })}



            {PLAYLIST.map((item) => {
							<div className={styles.PlaylistSongs}>
                            {item.playlistData.map((song) => {
                                return (
                                    <button 
                                        key={song.index} 
                                        onClick={() => props.changeTrack([PLAYLIST.indexOf(item), item.playlistData.indexOf(song)])} 
                                        className={styles.SongBtn}
                                    >
                                        <PlaylistTrack 
                                            data={{
                                                listType: item.type,
                                                song: song
                                            }}
                                        />
                                    </button>
                                );
                            })}
                        </div>
            })}



        </div>

    );
}

const mapStateToProps = (state) => {
	return {
		trackData: state.trackData,
	};
};


export default connect(mapStateToProps, { changeTrack })(Test);