import { PLAYLIST } from "../data/index";
import { PLAYPAUSE, CHANGETRACK, LIKETRACK, TOGGLEPLAYLIST, INITIALLOAD } from "../actions/index";
import CONST from '../constants';
import ReactGA from "react-ga4";

const INITIAL_LIKED_PLAYLISTS = [{type: 'playlist', title: 'Songs liked', link: CONST.LINK_PLAYLIST_LIKED, imgUrl: 'https://mdundo.com/media/playlist/45673_3sD2xjv2_t.jpg', info:'', playlistData: []}]

const INITIAL_STATE = {
  trackData: {
    //trackKey: [0, 0],
    // link: `${PLAYLIST[0].playlistData[0].link}`,
    // trackName: `${PLAYLIST[0].playlistData[0].trackName}`,
    // trackImg: `${PLAYLIST[0].playlistData[0].trackImg}`,
    // trackArtist: `${PLAYLIST[0].playlistData[0].trackArtist}`,
    trackLink: undefined,
    trackName: undefined,
    trackImg: undefined,
    trackArtist: undefined,
    trackPlaylist : undefined,
    trackIndex: 0,
    trackPlaylistLink: '',
    trackLiked: false,
    trackTime: 0
  },
  isPlaying: false,
  // isLiked: false,
  likedPlaylists: [],
  // playlistLike: 
  //   {type: 'playlist', title: 'Songs liked', link: 'playlist/liked', imgUrl: 'https://mdundo.com/media/playlist/45673_3sD2xjv2_t.jpg', playlistData: []}
  // ,
  // playlists: [],
};

export const reducer = (state = INITIAL_STATE, action) => {
  
  switch (action.type) {
    case TOGGLEPLAYLIST:
      
      let playlists = [...state.likedPlaylists];
      if (!(playlists.filter(e => e.link === action.payload.link).length > 0)) {
        console.log("BB");
        playlists = [...state.likedPlaylists, action.payload]
      } else {
        console.log("AA");
        console.log(state.likedPlaylists);
        playlists = playlists.filter(e => e.link !== action.payload.link)
        console.log(playlists);
      }
      localStorage.setItem('STORAGEPLAYLISTS', JSON.stringify(playlists));
      return {
        ...state,
        likedPlaylists: playlists,
        //isLiked: !state.isLiked
      };
    case INITIALLOAD:
      let storagePlaylists = JSON.parse
        (localStorage.getItem('STORAGEPLAYLISTS') || JSON.stringify(INITIAL_LIKED_PLAYLISTS));
      
        console.log('A');
        console.log(storagePlaylists);
        console.log('B');
        return {
          ...state,
          likedPlaylists: storagePlaylists
        };
    case LIKETRACK:
      let songsLiked = [...state.likedPlaylists[CONST.PLAYLIST_INDEX_LIKED].playlistData];
      let liked = false
      if (!(songsLiked.filter(e => e.trackLink === action.payload.trackLink).length > 0)) {
        songsLiked = [...songsLiked, action.payload]
        liked = true
      } else {
        songsLiked = songsLiked.filter(e => e.trackLink !== action.payload.trackLink)
      }

      //reassign song indexes for liked songs
      var i ;
      for(i=0; i < songsLiked.length; i++){
        songsLiked[i].trackIndex = i+1;
      }

      let playlistsForLike = [...state.likedPlaylists];
      playlistsForLike[CONST.PLAYLIST_INDEX_LIKED].playlistData = songsLiked;
      localStorage.setItem('STORAGEPLAYLISTS', JSON.stringify(playlistsForLike));
      return {
        ...state,
        trackData: {
          ...state.trackData,
          trackLiked: liked
        },
        likedPlaylists: playlistsForLike
      }
      //const index = this.state.employees.findIndex(emp => emp.id === employee.id);
      //employees = [...this.state.employees]; // important to create a copy, otherwise you'll modify state outside of setState call
      //employees[index] = employee;
      //this.setState({employees});
      console.log(action.payload.trackLiked);
      //const liked = action.payload.trackLiked;
      // return {
      //   ...state,
      //   trackData: {
      //     ...state.trackData,
      //     trackLiked: !liked
      //   }
      // };
    case PLAYPAUSE:
      return {
        ...state,
        isPlaying: action.payload
      };
    case CHANGETRACK:
      const songLink = action.payload[2].playlistData[action.payload[1]].trackLink;
      const songLiked = ((state.likedPlaylists[CONST.PLAYLIST_INDEX_LIKED].playlistData.filter(e => e.trackLink === songLink).length > 0));

      ReactGA.event({
        category: "player",
        action: "change_track",
        label: action.payload[2].playlistData[action.payload[1]].trackName
      });

      return {
        ...state,
        trackData: {
          ...state.trackData,
          //trackKey: [action.payload[0], action.payload[1]],
          trackLink: `${
            //PLAYLIST[action.payload[0]].playlistData[action.payload[1]].trackLink
            action.payload[2].playlistData[action.payload[1]].trackLink
          }`,
          trackName: `${
            //PLAYLIST[action.payload[0]].playlistData[action.payload[1]].trackName
            action.payload[2].playlistData[action.payload[1]].trackName
          }`,
          trackImg: `${
            //PLAYLIST[action.payload[0]].playlistData[action.payload[1]].trackImg
            action.payload[2].playlistData[action.payload[1]].trackImg
          }`,
          trackArtist: `${
            //PLAYLIST[action.payload[0]].playlistData[action.payload[1]].trackArtist
            action.payload[2].playlistData[action.payload[1]].trackArtist
          }`,
          trackTime: `${
            //PLAYLIST[action.payload[0]].playlistData[action.payload[1]].trackArtist
            action.payload[2].playlistData[action.payload[1]].trackTime
          }`,
          trackPlaylist: action.payload[2],
          trackIndex: action.payload[1],
          trackPlaylistLink: action.payload[2].link,
          trackLiked: songLiked
        }
      };
    default:
      return state;
  }
};
