export const PLAYPAUSE = "PLAYPAUSE";
export const CHANGETRACK = "CHANGETRACK";
export const LIKETRACK = "LIKETRACK";
export const TOGGLEPLAYLIST = "TOGGLEPLAYLIST";
export const INITIALLOAD = "INITIALLOAD";

export const changePlay = (isPlaying) => {
  return { type: PLAYPAUSE, payload: isPlaying };
};

export const changeTrack = (trackKey) => {
  return { type: CHANGETRACK, payload: trackKey };
};

export const changeLike = (isLiked) => {
  return { type: LIKETRACK, payload: isLiked };
};

export const togglePlaylist = (data) => {
  return { type: TOGGLEPLAYLIST, payload: data };
};

export const initialLoad = (data) => {
  return { type: INITIALLOAD, payload: data };
};