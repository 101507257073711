import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import styles from './playlist.module.css';

import TitleS from '../text/title-s';
import TextRegularM from '../text/text-regular-m';
import PlaylistButton from './playlist-button';
import { PLAYLISTBTN } from '../../constants';
import { PLAYLIST } from '../../data';
import CONST from "../../constants";

function Playlist(props) {
    console.log(props)
    return (
      <div className={styles.Playlist}>

        {/* <TitleS>Çalma Listeleri</TitleS>

        <div>
          {PLAYLISTBTN.map((playlist) => {
            return (
                <PlaylistButton 
                  href={playlist.path} 
                  ImgName={playlist.ImgName}
                  key={playlist.title}
                >
                  {playlist.title}
                </PlaylistButton>
            );
          })}
        </div>

        <hr className={styles.hr}/> */}

        {/* <TitleS>Playlists</TitleS> */}

        {/* <div>
          {PLAYLIST.filter((item) => item.type === 'playlist111').map((list) => {
            return (
              <Link to={`/playlist/${list.link}`} key={list.title}>
                  <TextRegularM>{list.title}</TextRegularM>
              </Link>
            );
          })}
        </div> */}

        <div>
          {props.likedPlaylists.map((list) => {
            if (list.link !== CONST.LINK_PLAYLIST_LIKED || (list.link === CONST.LINK_PLAYLIST_LIKED && list.playlistData.length > 0)) 
            return (
              <Link to={`/${list.link}`} key={list.link}>
                  <TextRegularM>{list.title}</TextRegularM>
              </Link>
            );
          })}
                    
        </div>
      </div>
    );
}
  

const mapStateToProps = (state) => {
  return {
    likedPlaylists: state.likedPlaylists
  };
};

export default connect(mapStateToProps)(Playlist);