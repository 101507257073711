import Topnav from '../component/topnav/topnav';
import TitleM from '../component/text/title-m';
import TextRegularM from "../component/text/text-regular-m";
import SearchPageCard from '../component/cards/searchpage-card';
import PlaylistSongs from '../component/playlist/playlist-songs';
import * as Icons from '../component/icons';
import { SEARCHCARDS } from '../data/index';
import { useEffect, useState } from 'react';
import styles from "./search.module.css";
import { cacheGet, cacheSet } from '../functions/cacheManager';
import CONST from '../constants';

function Search(props){
    const[genres, setGenres] = useState([]);
    const[searchPlaylist, setSearchPlaylist] = useState(undefined);
    const[prevSearch, setPrevSearch] = useState('');
    const[searching, setSearching] = useState(false);

    
    useEffect(() => {
        const url = CONST.API_URL_BASE+'spa_genres';    
    
        let cache_data = cacheGet(url);
        if (cache_data != null) {
            setGenres(cache_data)
            return
        }

		fetch(url)
		   .then((response) => response.json())
		   .then((data) => {
			  setGenres(data)
              cacheSet(url, 10000, data)
		   })
		   .catch((err) => {
			  console.log(err.message);
		   });
	 }, []);

     useEffect(() => {

    }, [searching]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            let searchText = event.target.value
            if (searchText.length > 0 && searchText != prevSearch && !searching) {
                setPrevSearch(searchText);
                setSearching(true);

                const url = CONST.API_URL_BASE+'spa_playlist_id?type=playlist&id=46343'
                console.log(url);
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => {
                        console.log(data);
                        if (Object.keys(data).length > 0) setSearchPlaylist(data);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
                setSearching(false);
            }
          
          //show loading or/and lock input
          //hide genres
          //show list of songs
        }
    } 

    return (
        <div className={styles.SearchPage}>
            <Topnav search={true} handleKeyDown={handleKeyDown}/>

            {(() => {
				if (!searching && searchPlaylist === undefined) {
				return (
                    <div className={styles.Search}>
                        <TitleM>Browse genres</TitleM>
                        <div className={styles.SearchCardGrid}>
                            {genres.map((card) => {
                                return (
                                    <SearchPageCard 
                                        key={card.link}
                                        cardData={{
                                            bgcolor: card.playlistBg,
                                            title: card.title,
                                            imgurl: card.imgUrl,
                                            link: card.link
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )
                }
            })()}                    

            {(() => {
				if (!searching && searchPlaylist !== undefined) {
				return (
                    <PlaylistSongs data={{thisPlaylist: searchPlaylist}}/>
                )
                }
                })()}

        </div>
    );
}

export default Search;