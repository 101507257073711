import * as Icons from '../icons';
import styles from './search-box.module.css';

function SearchBox({handleKeyDown}) {

    const onKeyDown = (event) => {
        handleKeyDown(event);
    };

    console.log(handleKeyDown)
    return (
        <div className={styles.SeachBox}>
            <Icons.Search />
            <input placeholder="Sanatçılar, şarkılar veya podcast'ler" maxLength="80" onKeyDown={onKeyDown} />
        </div>
    );
}
  
export default SearchBox;