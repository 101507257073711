import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { changeTrack } from '../actions';
import Topnav from '../component/topnav/topnav';
import TextRegularM from "../component/text/text-regular-m";
import PlayButton from '../component/buttons/play-button';
import IconButton from '../component/buttons/icon-button';
import PlaylistDetails from '../component/playlist/playlist-details';
import PlaylistTrack from '../component/playlist/playlist-track';
import PlaylistSongs from '../component/playlist/playlist-songs';
import * as Icons from '../component/icons';
import { PLAYLIST } from "../data/index";

import styles from './playlist.module.css';
import { useEffect, useState } from 'react';
import LikeButton from '../component/buttons/like-button';
import { ColorExtractor } from "react-color-extractor";


import CONST from '../constants';

function PlaylistPage(props) {
	const[thisPlaylist, setThisPlaylist] = useState(undefined)
	const[playlistIndex, setPlaylistIndex] = useState(undefined);
	const[isthisplay, setIsthisPlay] = useState(false);
	const[isPlaylistSpecial, setIsPlaylistSpecial] = useState(false);//liked or recent
	const { path } = useParams();

	const parseColors = (detectedColorCodes) => {
		if (detectedColorCodes[1] !== undefined) {
			console.log(detectedColorCodes[1])
			changeBg(detectedColorCodes[1])
		} else if (detectedColorCodes[0] !== undefined) {
			changeBg(detectedColorCodes[0])
		}

		// if (detectedColorCodes[0] !== undefined) changeBg(detectedColorCodes[0])
	  };
	
	function changeBg(color){
		document.documentElement.style.setProperty('--hover-home-bg', color);
	}
	
	useEffect(() => {
		setIsthisPlay(playlistIndex === props.trackData.trackPlaylistLink);
	}, [props.trackData.trackPlaylistLink])
	
	useEffect(() => {
		
		console.log(props);
		if (props.type + '/' + path == CONST.LINK_PLAYLIST_LIKED) {
			setIsPlaylistSpecial(true);
			setThisPlaylist(props.likedPlaylists[CONST.PLAYLIST_INDEX_LIKED]);
			return;
		}
		setThisPlaylist(undefined);
		setIsPlaylistSpecial(false);
		const url = CONST.API_URL_BASE+'spa_playlist_id?type='+props.type+'&id='+path;
		console.log(url);
		fetch(url)
		   .then((response) => response.json())
		   .then((data) => {
				console.log(data);
				if (Object.keys(data).length > 0) setThisPlaylist(data);
		   })
		   .catch((err) => {
				console.log(err.message);
		   });
	 }, [path]);

	return (
		<div className={styles.PlaylistPage}>
			<div className={styles.gradientBg}></div>
            <div className={styles.gradientBgSoft}></div>
			<div className={styles.Bg}></div>

			<Topnav />

			{(() => {
				if (thisPlaylist !== undefined) {
				return (


					<div key={thisPlaylist.title} onLoad={() => {
						// changeBg(thisPlaylist.playlistBg);
						setPlaylistIndex(thisPlaylist.link)
					}}>

						<PlaylistDetails data={thisPlaylist} />

						<div className={styles.PlaylistIcons}>

							<ColorExtractor getColors={parseColors} src={thisPlaylist.imgUrl}/>

							<button onClick={() => props.changeTrack([0, 0, thisPlaylist])} >
								<PlayButton isthisplay={isthisplay}/>
							</button>
							{/* <IconButton isActive={true} icon={<Icons.Like />} activeicon={<Icons.LikeActive />}/>  */}

							
							{!isPlaylistSpecial && 
								<LikeButton data={thisPlaylist}/>
							}

							{!isPlaylistSpecial && 
								<a href="https://www.facebook.com/sharer/sharer.php?u=mdundo.com" target="_blank">
									<Icons.More className={styles.moreIcon}/>
								</a>
							}
						</div>

						<PlaylistSongs data={{thisPlaylist: thisPlaylist}}/>

						{/* <div className={styles.ListHead}>
							<TextRegularM>#</TextRegularM>
							<TextRegularM>TITLE</TextRegularM>
							<Icons.Time/>
						</div>

						<div className={styles.PlaylistSongs}>
							{thisPlaylist.playlistData.map((song) => {
								return (
									<button 
										key={song.index} 
										onClick={() => props.changeTrack([0, thisPlaylist.playlistData.indexOf(song), thisPlaylist])} 
										className={styles.SongBtn}
									>
										<PlaylistTrack 
											data={{
												listType: thisPlaylist.type,
												song: song
											}}
										/>
									</button>
								);
							})}
						</div> */}


					</div>					



				)
				}
			})()}



			{/* { PLAYLIST.map((item) => {
                if(item.link == path){
                    return (
                        <div key={item.title} onLoad={() => {
							changeBg(item.playlistBg);
							setPlaylistIndex(PLAYLIST.indexOf(item))
						}}>

							<PlaylistDetails data={item} />

							<div className={styles.PlaylistIcons}>
								<button
									onClick={() => props.changeTrack([PLAYLIST.indexOf(item), 0])} 
								>
									<PlayButton isthisplay={isthisplay}/>
								</button>
								<IconButton icon={<Icons.Like />} activeicon={<Icons.LikeActive />}/>
								<LikeButton data={item}/>
								<Icons.More className={styles.moreIcon}/>
							</div>

							<div className={styles.ListHead}>
								<TextRegularM>#</TextRegularM>
								<TextRegularM>BAŞLIK</TextRegularM>
								<Icons.Time/>
							</div>

							<div className={styles.PlaylistSongs}>
								{item.playlistData.map((song) => {
									return (
										<button 
											key={song.index} 
											onClick={() => props.changeTrack([PLAYLIST.indexOf(item), item.playlistData.indexOf(song)])} 
											className={styles.SongBtn}
										>
											<PlaylistTrack 
												data={{
													listType: item.type,
													song: song
												}}
											/>
										</button>
									);
								})}
							</div>
                        </div>
                    );
                }
			})} */}
		</div>
	);
}



const mapStateToProps = (state) => {
	return {
		trackData: state.trackData,
		likedPlaylists: state.likedPlaylists,
	};
};
  
export default connect(mapStateToProps, { changeTrack })(PlaylistPage);