import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TitleM from '../component/text/title-m';
import Topnav from '../component/topnav/topnav';
import PlaylistCardM from '../component/cards/playlist-card-m'
import { PLAYLIST } from "../data/index";
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import CONST from '../constants';

import styles from "./library.module.css";

function Library(props){
    const[playlists, setPlaylists] = useState([])

    useEffect(() => {
		setPlaylists(props.likedPlaylists);	
        console.log(props)
	})



    return (
        <div className={styles.LibPage}>
                <Topnav tabButtons={true} />
                <div className={styles.Library}>
                        <Route exact path="/library"><PlaylistTab playlists={playlists}/></Route>
                        <Route path="/library/charts"><ChartTab playlists={playlists}/></Route>
                        <Route path="/library/genres"><GenreTab playlists={playlists}/></Route>
                        <Route path="/library/artists"><ArtistTab playlists={playlists}/></Route>
                        <Route path="/library/albums"><AlbumTab playlists={playlists}/></Route>
                </div>
        </div>
    );
}

function PlaylistTab(data){
    return (
        <div>
            <TitleM>Your playlists</TitleM>
            <div className={styles.Grid}>
                {data.playlists.filter(item => item.type == CONST.PLAYLIST_TYPE_PLAYLIST).map((item) => {
                    return (
                        <PlaylistCardM key={item.link} data={item} />
                    );
                })}
            </div>
        </div>
    );
}

function ChartTab(data){
    return (
        <div>
            <TitleM>Charts</TitleM>
            <div className={styles.Grid}>
                {data.playlists.filter(item => item.type == CONST.PLAYLIST_TYPE_CHART).map((item) => {
                    return (
                        <PlaylistCardM key={item.link} data={item} />
                    );
                })}
            </div>
        </div>
    );
}

function GenreTab(data){
    return (
        <div>
            <TitleM>Genres</TitleM>
            <div className={styles.Grid}>
                {data.playlists.filter(item => item.type == CONST.PLAYLIST_TYPE_GENRE).map((item) => {
                    return (
                        <PlaylistCardM key={item.link} data={item} />
                    );
                })}
            </div>
        </div>
    );
}

function ArtistTab(data){
    return (
        <div>
            <TitleM>Artists</TitleM>
            <div className={styles.Grid}>
                {data.playlists.filter(item => item.type == CONST.PLAYLIST_TYPE_ARTIST).map((item) => {
                    return (
                        <PlaylistCardM key={item.link} data={item} />
                    );
                })}
            </div>
        </div>
    );
}

function AlbumTab(data){
    return (
        <div>
            <TitleM>Albums</TitleM>
            <div className={styles.Grid}>
                {data.playlists.filter(item => item.type == CONST.PLAYLIST_TYPE_ALBUM).map((item) => {
                    return (
                        <PlaylistCardM key={item.link} data={item} />
                    );
                })}
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
	return {
		likedPlaylists: state.likedPlaylists,
	};
};

export default connect(mapStateToProps)(Library);