import * as Icons from '../icons';
import TextRegularM from "../text/text-regular-m";
import PlaylistTrack from '../playlist/playlist-track';
import styles from "./playlist-songs.module.css";
import { connect } from 'react-redux';
import {changeTrack}  from '../../actions';

function PlaylistSongs(props) {
    
	return (
    <div>
        <div className={styles.ListHead}>
            <TextRegularM>#</TextRegularM>
            <TextRegularM>TITLE</TextRegularM>
            <Icons.Time/>
        </div>

        <div className={styles.PlaylistSongs}>
            {props.data.thisPlaylist.playlistData.map((song) => {
                return (
                    <button 
                        key={song.index} 
                        onClick={() => props.changeTrack([0, props.data.thisPlaylist.playlistData.indexOf(song), props.data.thisPlaylist])} 
                        className={styles.SongBtn}
                    >
                        <PlaylistTrack 
                            data={{
                                listType: props.data.thisPlaylist.type,
                                song: song
                            }}
                        />
                    </button>
                );
            })}
        </div>
    </div>
	);
}

const mapStateToProps = (state) => {
	return {

	};
};

export default connect(mapStateToProps, { changeTrack })(PlaylistSongs);
