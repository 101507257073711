import { connect } from 'react-redux';
import { changeTrack } from '../../../actions';
import * as Icons from '../../icons';
import IconButton from '../../buttons/icon-button';
import PlayButton from '../../buttons/play-button';
import styles from "./music-control-box.module.css";

function MusicControlBox(props){

    function decreaseIndex(){
        if(props.trackData.trackIndex == 0){ }else{
            props.changeTrack([0, props.trackData.trackIndex-1, props.trackData.trackPlaylist])
        }
    }
    function increaseIndex(){
        if(props.trackData.trackIndex == (props.trackData.trackPlaylist.playlistData.length)-1){ }else{
            props.changeTrack([0, props.trackData.trackIndex+1, props.trackData.trackPlaylist])
        }
    }

    return (
        <div className={styles.musicControl}>
            <IconButton icon={<Icons.Mix />} activeicon={<Icons.Mix />}/>
            <button className={styles.button} onClick={decreaseIndex}>
                <Icons.Prev />
            </button>
            <PlayButton isthisplay={true}/>
            <button className={styles.button} onClick={increaseIndex}>
                <Icons.Next />
            </button>
            <IconButton icon={<Icons.Loop />} activeicon={<Icons.Loop />}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      trackData: state.trackData
    };
};
  
export default connect(mapStateToProps, { changeTrack })(MusicControlBox);