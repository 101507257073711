export const cacheGet = (url) => {
   const cache_exp = localStorage.getItem('cache_exp'+url) 
   const cache_data = JSON.parse(localStorage.getItem('cache_data'+url) )

   if (cache_exp!=null && cache_exp > Date.now() && cache_data!=null && cache_data.length>0) {
       console.log('using cache')
       return cache_data
   }

   return null
};

export const cacheSet = (url, ttl, data) => {
   localStorage.setItem('cache_exp'+url, Date.now()+ttl);
   localStorage.setItem('cache_data'+url, JSON.stringify(data));
};