import React from 'react';
import { connect } from 'react-redux';
import { changeLike } from '../../actions';
import { togglePlaylist } from '../../actions';
import * as Icons from '../icons';
import IconButton from '../buttons/icon-button';

function LikeButton(props) {
  const liked = ((props.likedPlaylists.filter(e => e.link === props.data.link).length > 0)) ;
  return (
      <button tabIndex={1} onClick={() => props.togglePlaylist(props.data)}>                        
                  <IconButton isActive={liked} icon={<Icons.Like />} activeicon={<Icons.LikeActive />}/>
      </button>

  );
}

const mapStateToProps = (state) => {
  return {
    //isLiked: state.isLiked,
    likedPlaylists: state.likedPlaylists
  };
};

export default connect(mapStateToProps, { togglePlaylist })(LikeButton);