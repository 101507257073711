import React, { useEffect  } from 'react';
import { BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import useWindowSize from './hooks/useWindowSize';
import Sidebar from './component/sidebar/sidebar';
import MobileNavigation from './component/sidebar/mobile-navigation';
import Footer from './component/footer/footer';
import Home from './pages/home';
import Search from './pages/search';
import Library from './pages/library';
import PlaylistPage from './pages/playlist';
import Test from './pages/test';

import CONST from './constants/index';
import styles from './style/App.module.css';

import { connect } from 'react-redux';
import { initialLoad } from './actions';
import ReactGA from "react-ga4";


function App(props) {
  const size = useWindowSize();

  const TRACKING_ID = "G-4HM7NX2FD9"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);


  useEffect(() => {
    console.log('APP loaded');
    props.initialLoad();
	 }, []);
  

  return (
        <Router>
        <div className={styles.layout}>
          {size.width > CONST.MOBILE_SIZE 
            ? <Sidebar /> 
            : <MobileNavigation />
          }
          <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/search">
                <Search />
            </Route>
            <Route path="/library">
                <Library />
            </Route>
            <Route path="/test">
                <Test />
            </Route>
            <Route exact path="/playlist/:path">
                <PlaylistPage type={CONST.PLAYLIST_TYPE_PLAYLIST} />
            </Route>
            <Route exact path="/album/:path">
                <PlaylistPage type={CONST.PLAYLIST_TYPE_ALBUM} />
            </Route>
            <Route exact path="/artist/:path">
                <PlaylistPage type={CONST.PLAYLIST_TYPE_ARTIST} />
            </Route>            
            <Route exact path="/genre/:path">
                <PlaylistPage type={CONST.PLAYLIST_TYPE_GENRE} />
            </Route>            
            <Route exact path="/chart/:path">
                <PlaylistPage type={CONST.PLAYLIST_TYPE_CHART} />
            </Route>            
          </Switch>
          <Footer />
        </div>
      </Router>
  );
}

const mapStateToProps = (state) => {
	return {
    //playlists: state.playlists
	};
};



export default connect(mapStateToProps, { initialLoad })(App);