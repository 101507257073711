import { connect } from "react-redux";
import * as Icons from '../icons';
import TextRegularM from '../text/text-regular-m';
import IconButton from '../buttons/icon-button';
import ToggleButton from '../buttons/toggle-button';
import { changeLike } from '../../actions';
import { useEffect, useState } from 'react';
import styles from "./footer-left.module.css";
import { Link } from "react-router-dom";

function FooterLeft(props) {
    // const[isThisLiked, setIsThisLiked] = useState(false);
    // const liked = props.trackData.trackLiked;

    const toggleSong = (state) => {
        props.changeLike(props.trackData)
        //console.log("Toggled:", state);
    };

    // useEffect(() => {
    // 	setIsThisLiked( props.trackData.trackLiked)
    //     console.log("IsLiked:", props.trackData.trackLiked);
    // }, [])

    return (
        <div className={styles.footerLeft}>
            <ImgBox
                trackData={props.trackData}
            />
            <SongDetails
                trackData={props.trackData}
            />

            <ToggleButton
                isActive={props.trackData.trackLiked}
                onClick={toggleSong}
                icon={<Icons.Like />}
                iconActive={<Icons.LikeActive />}
            />

        </div>
    );
}

function ImgBox({ trackData }) {
    let link = '';
    if (trackData.trackPlaylist != undefined) link = '/'+trackData.trackPlaylist.link;
    return (
        <Link to={link}>
            <div className={styles.imgBox}>
                <img src={trackData.trackImg} alt="" />
            </div>
        </Link>
    );
}

function SongDetails({ trackData }) { 
    return (
            <div className={styles.songDetails}>
                <TextRegularM>{trackData.trackName}</TextRegularM>
                <TextRegularM><small>{trackData.trackArtist}</small></TextRegularM>
            </div>
    );
}


const mapStateToProps = (state) => {
    // console.log(state.trackData);
    // console.log(state.trackData.trackLiked);
    return {
        trackData: state.trackData
    };
};

export default connect(mapStateToProps, { changeLike })(FooterLeft);